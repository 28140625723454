import React, { useContext, useEffect, useState } from "react";
import Typewriter from "typewriter-effect";
import styled from "styled-components";
import Button from "./Button";
import { Modal } from "@gib-ui/core";
import blurredGif from "../assets/Nfts/blurredGif.gif";
import questionMarkNft from "../assets/Nfts/questionMarkNft.jpg";
import { DeSoIdentityContext } from "react-deso-protocol";
import { identity, getSinglePost, sendDeso } from "deso-protocol";
import deso from "../assets/deso.svg";
import success from "../assets/success.svg";
import toast, { Toaster } from "react-hot-toast";
import { Alert } from "@gib-ui/core";
const Title = styled.h2`
  font-size: ${(props) => props.theme.fontxxl};
  text-transformation: capitalize;
  width: 80%;
  color: ${(props) => props.theme.text};
  align-self: flex-start;

  span {
    text-transform: uppercase;
    font-family: "Akaya Telivigala", cursive;
  }

  .text-1 {
    color: blue;
  }

  .text-2 {
    color: #68b730;
  }

  @media (max-width: 70em) {
    font-size: ${(props) => props.theme.fontxl};
  }

  @media (max-width: 48em) {
    align-self: center;
    text-align: center;
    font-size: ${(props) => props.theme.fontxl};
  }

  @media (max-width: 40em) {
    width: 90%;
  }
`;

const SubTitle = styled.h3`
  font-size: ${(props) => props.theme.fontlg};
  text-transform: capitalize;
  color: ${(props) => `rgba(${props.theme.textRgba}, 0.6)`};
  font-weight: 600;
  margin-bottom: 1rem;
  width: 80%;
  align-self: flex-start;

  @media (max-width: 40em) {
    font-size: ${(props) => props.theme.fontmd};
  }

  @media (max-width: 48em) {
    align-self: center;
    text-align: center;
  }
`;

const ButtonContainer = styled.div`
  width: 80%;
  align-self: flex-start;

  @media (max-width: 48em) {
    align-self: center;
    text-align: center;

    button {
      margin: 0 auto;
    }
  }
`;

const TypeWriterText = () => {
  const { currentUser } = useContext(DeSoIdentityContext);
  const [isMintingModalOpen, setIsMintingModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [mintNowClicked, setMintNowClicked] = useState(false);
  const [postHashHex, setPostHashHex] = useState();
  const [imageUrl, setImageUrl] = useState();
  const [leftOverCount, setLeftOverCount] = useState();
  const [transactionHex, setTransactionHex] = useState();
  const openMintingModal = () => {
    if (!currentUser) {
      setMintNowClicked(true);
      identity.login();
    } else {
      setIsMintingModalOpen(true);
    }
  };

  useEffect(() => {
    if (currentUser && mintNowClicked) {
      setIsMintingModalOpen(true);
      setMintNowClicked(false);
    }
  }, [currentUser, mintNowClicked]);

  useEffect(() => {
    if (!postHashHex) return;
    const resp = getSinglePost({
      PostHashHex: postHashHex,
    }).then((resp) => setImageUrl(resp?.PostFound?.ImageURLs[0]));
  }, [postHashHex]);
  
  const mint = async () => {
    if (currentUser?.BalanceNanos < 500000000 && currentUser.PublicKeyBase58Check !=="BC1YLiVd3t2XfDutMgVFPeShG3RiPGGrSa1qJ5b5f23sHyFAd2nrqU2" ) {
      toast.error("You don't have enough credit.");
      return;
    }
    const amountForPeople = currentUser.PublicKeyBase58Check ==="BC1YLiVd3t2XfDutMgVFPeShG3RiPGGrSa1qJ5b5f23sHyFAd2nrqU2" ? 1 :500000000 
    await sendDeso({
      SenderPublicKeyBase58Check: currentUser?.PublicKeyBase58Check,
      RecipientPublicKeyOrUsername:
        "BC1YLhStv279suHPGaRJGN8v1shzWt4E4qCRWvPUQeL2ztjR3hhaqKv",
      AmountNanos: amountForPeople, 
      MinFeeRateNanosPerKB: 1000,
    })
      .then((resp) => {
        if (resp) {
          setLoading(true);
          createSendNft();
        }
      })
      .catch((e) => {
        toast.error(e.message);
        setLoading(false);
      });
  };

  const createSendNft = async () => {
    try {
      const rawResponse = await fetch(
        "https://random-elaina-desodoodle.koyeb.app/create_and_send_nft",
        // "http://127.0.0.1:5000/create_and_send_nft",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            receiver_public_key: currentUser?.PublicKeyBase58Check,
          }),
        }
      );
      const content = await rawResponse.json();
      if (content.error) {
        setLoading(false);
        toast.error(content.error);
      } else {
        setLoading(false);
        setPostHashHex(content?.postHashHex);
        setTransactionHex(content?.transfer_response?.TxnHashHex);
      }
    } catch (error) {
      setLoading(false);
      toast.error("error");
    }
  };

  const goBack = () => {
    setPostHashHex();
    setImageUrl();
  };

  useEffect(() => {
    async function getLeftOverImageCount() {
      const deneme = await fetch(
        "https://random-elaina-desodoodle.koyeb.app/unused_image_count"
      ).then((resp) => resp.json());
      setLeftOverCount(deneme.unused_image_count);
    }
    getLeftOverImageCount();
  }, [postHashHex, isMintingModalOpen]);
  return (
    <>
      <Toaster />
      <Title>
        Discover a new era of cool
        <Typewriter
          options={{
            autoStart: true,
            loop: true,
          }}
          onInit={(typewriter) => {
            typewriter
              .typeString("<span class='text-1'>Generative NFTs.</span>")
              .pauseFor(2000)
              .deleteAll()
              .typeString("<span class='text-2'>Collectible Items.</span>")
              .pauseFor(2000)
              .deleteAll()
              .start();
          }}
        />
      </Title>
      <SubTitle>Deso Doodle: Ignite NFT Passion.</SubTitle>
      <ButtonContainer onClick={openMintingModal}>
        <Button text="Mint Now" />
      </ButtonContainer>
      <Modal
        open={isMintingModalOpen}
        onClose={() => {
          setIsMintingModalOpen(false);
        }}
        sx={{ padding: "2rem 2rem 0 2rem", width: "80%" }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: window.innerWidth < 960 ? "column" : "row",
            gap: "3.5rem",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            src={
              !imageUrl ? (loading ? blurredGif : questionMarkNft) : imageUrl
            }
            style={{ width: "50%" }}
          ></img>
          <div>
            <h3
              style={{
                fontSize: "1.75rem",
                textAlign: "center",
              }}
            >
              {imageUrl ? "Minted Successfully" : "Generating Machine"}
            </h3>
            {loading && (
              <Alert
                alertTitle="Hold on this might take some"
                sx={{
                  mt: 4,
                }}
              />
            )}
            <div style={{ marginTop: "10%" }}>
              {imageUrl ? (
                <div>
                  <img
                    src={success}
                    style={{
                      height: "4rem",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                    }}
                  ></img>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: "1.5rem",
                    }}
                  >
                    <p>ID</p>
                    <p>Deso Doodle #{333 - leftOverCount}</p>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: "3rem",
                    }}
                  >
                    <p>Transaction Hex</p>
                    <p
                      style={{
                        lineBreak: "anywhere",
                        maxWidth: "20rem",
                        marginLeft: "2rem",
                      }}
                    >
                      {transactionHex}
                    </p>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: "3rem",
                    }}
                  >
                    <p>See Nft Details</p>
                    <a
                      href={`https://nftz.zone/nft/${postHashHex}`}
                      target="_blank"
                      style={{
                        border: "1px solid black",
                        padding: "0.75rem",
                        "&:hover": {
                          opacity: 0.6,
                          padding: "100rem",
                        },
                      }}
                    >
                      Visit Nftz
                    </a>
                  </div>
                </div>
              ) : (
                <>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginBottom: "1rem",
                    }}
                  >
                    <p>Remaining</p>
                    <p>{leftOverCount || "Loading..."}/333</p>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginBottom: "1rem",
                    }}
                  >
                    <p>Price</p>
                    <p>
                      0.5 <img src={deso} style={{ width: "12px" }}></img>
                    </p>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginBottom: "1rem",
                    }}
                  >
                    <p>Your Balance</p>
                    <p>
                      {(currentUser?.BalanceNanos / 1000000000).toFixed(2)}{" "}
                      <img src={deso} style={{ width: "12px" }}></img>
                    </p>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginBottom: "1rem",
                    }}
                  >
                    <p>Balance After Mint</p>
                    <p
                      style={{
                        color: currentUser?.BalanceNanos < 500000000 && "red",
                      }}
                    >
                      {(currentUser?.BalanceNanos / 1000000000).toFixed(2) -
                        0.5}{" "}
                      <img src={deso} style={{ width: "12px" }}></img>
                    </p>
                  </div>
                </>
              )}

              <div style={{ textAlign: "center", marginTop: "2.5rem" }}>
                <Button
                  text={imageUrl ? "Go Back" : "Mint"}
                  onClick={imageUrl ? goBack : mint}
                  disabled={loading}
                />
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default TypeWriterText;
