import React, { useContext, useState } from "react";
import styled from "styled-components";
import Button from "./Button";
import Logo from "./Logo";
import { identity, } from "deso-protocol";
import { DeSoIdentityContext } from "react-deso-protocol";
import { Skeleton } from "@gib-ui/core";
import "./light.css";
import ClickAwayListener from "react-click-away-listener";

const Section = styled.section`
  width: 100vw;
  background-color: ${(props) => props.theme.body};
`;

const NavBar = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 85%;
  height: ${(props) => props.theme.navHeight};
  margin: 0 auto;

  .mobile {
    display: none;
  }

  @media (max-width: 64em) {
    .desktop {
      display: none;
    }
    .mobile {
      display: inline-block;
    }
  }
`;

const Menuu = styled.ul`
  display: flex;
  justify-content: space-between;
  align-items: center;
  list-style: none;

  @media (max-width: 64em) {
    /* 1024 px */
    position: fixed;
    top: ${(props) => props.theme.navHeight};
    left: 0;
    right: 0;
    bottom: 0;
    width: 100vw;
    height: ${(props) => `calc(100vh - ${props.theme.navHeight})`};
    z-index: 50;
    background-color: ${(props) => `rgba(${props.theme.bodyRgba}, 0.85)`};
    backdrop-filter: blur(2px);

    transform: ${(props) =>
      props.click ? "translateY(0)" : "translateY(1000%)"};
    transition: all 0.3s ease;

    flex-direction: column;
    justify-content: center;

    touch-action: none;
  }
`;

const MenuItem = styled.li`
  margin: 0 1rem;
  color: ${(props) => props.theme.text};
  cursor: pointer;

  &::after {
    content: " ";
    display: block;
    width: 0%;
    height: 2px;
    background: ${(props) => props.theme.text};
    transition: width 0.3s ease;
  }

  &:hover::after {
    width: 100%;
  }

  @media (max-width: 64em) {
    margin: 1rem 0;

    &::after {
      display: none;
    }
  }
`;

const HamburguerMenu = styled.span`
  width: ${(props) => (props.click ? "2rem" : "1.5rem")};
  height: 2px;
  background: ${(props) => props.theme.text};

  position: absolute;
  top: 2rem;
  left: 50%;
  transform: ${(props) =>
    props.click
      ? "translateX(-50%) rotate(90deg)"
      : "translateX(-50%) rotate(0)"};

  display: none;
  justify-content: center;
  align-items: center;

  cursor: pointer;
  transition: all 0.3s ease;

  @media (max-width: 64em) {
    /* 1024 px */
    display: flex;
  }

  &::after,
  &::before {
    content: " ";
    width: ${(props) => (props.click ? "1rem" : "1.5rem")};
    height: 2px;
    right: ${(props) => (props.click ? "-2px" : "0")};
    background: ${(props) => props.theme.text};
    position: absolute;
    transition: all 0.3s ease;
  }

  &::after {
    top: ${(props) => (props.click ? "0.3rem" : "0.5rem")};
    transform: ${(props) => (props.click ? "rotate(-40deg)" : "rotate(0)")};
  }

  &::before {
    bottom: ${(props) => (props.click ? "0.3rem" : "0.5rem")};
    transform: ${(props) => (props.click ? "rotate(40deg)" : "rotate(0)")};
  }
`;

const Navigation = () => {
  const { currentUser, isLoading } = useContext(DeSoIdentityContext);

  const [click, setClick] = useState(false);
  const [isMenuOpened, setIsMenuOpened] = useState(false);
  const scrollTo = (id) => {
    let element = document.getElementById(id);

    element.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest",
    });

    setClick(!click);
  };
  const connectWallet = () => {
    identity.login();
  };

  const handleClickAway = () => {
    setIsMenuOpened(false);
  };

  return (
    <Section id="navigation">
      <NavBar>
        <Logo />
        <div style={{ padding: "1rem" }} onClick={() => setClick(!click)}>
          {" "}
          <HamburguerMenu click={click}>&nbsp;</HamburguerMenu>
        </div>
        <Menuu click={click}>
          <MenuItem onClick={() => scrollTo("home")}>Home</MenuItem>
          <MenuItem onClick={() => scrollTo("about")}>About</MenuItem>
          <MenuItem onClick={() => scrollTo("roadmap")}>Roadmap</MenuItem>
          <MenuItem onClick={() => scrollTo("showcase")}>Showcase</MenuItem>
          <MenuItem onClick={() => scrollTo("team")}>Team</MenuItem>
          <MenuItem onClick={() => scrollTo("faq")}>Faq</MenuItem>
          <MenuItem onClick={() =>window.open("https://deso-doodles.gitbook.io/deso-doodles/")}>Whitepaper</MenuItem>
          {!currentUser ? (
            <MenuItem>
              <div className="mobile">
                <Button text="Connect Wallet" onClick={connectWallet} />
              </div>
            </MenuItem>
          ) : (
            <MenuItem>
              <div className="mobile">
                <Button text="Disconnect" onClick={connectWallet} />
              </div>
            </MenuItem>
          )}
        </Menuu>

        {!currentUser && !isLoading ? (
          <div className="desktop">
            <Button text="Connect Wallet" onClick={connectWallet} />
          </div>
        ) : (
          <>
            {!currentUser ? (
              <Skeleton animation circular width={50} height={50}></Skeleton>
            ) : (
              <ClickAwayListener onClickAway={handleClickAway}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                  onClick={() => setIsMenuOpened(!isMenuOpened)}
                >
                  <img
                    src={`https://node.deso.org/api/v0/get-single-profile-picture/${currentUser?.PublicKeyBase58Check}`}
                    style={{
                      width: "45px",
                      borderRadius: "50%",
                    }}
                  />
                  {/* <span style={{ fontSize: "10px" }}>
                    {currentUser.PublicKeyBase58Check.slice(0, 8)}...
                    {currentUser.PublicKeyBase58Check.slice(16, 24)}
                  </span>
                  <span style={{ fontSize: "10px" }}>
                    Balance:{" "}
                    {(currentUser.BalanceNanos / 1000000000).toFixed(3)} Deso
                  </span>
                  <div className="green-light"></div> */}

                  {isMenuOpened && (
                    <div
                      style={{
                        position: "absolute",
                        top: "10%",
                        zIndex: "99",
                      }}
                    >
                      <Button
                        text="Disconnect"
                        onClick={() => identity.logout()}
                      />
                    </div>
                  )}
                </div>
              </ClickAwayListener>
            )}
          </>
        )}
      </NavBar>
    </Section>
  );
};

export default Navigation;
