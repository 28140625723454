import React, { useRef } from "react";
import styled from "styled-components";
import { keyframes } from "styled-components";

import img27 from "../../assets/ShowCase/27.png";
import img38 from "../../assets/ShowCase/38.png";
import img44 from "../../assets/ShowCase/44.png";
import img46 from "../../assets/ShowCase/46.png";
import img73 from "../../assets/ShowCase/73.png";
import img74 from "../../assets/ShowCase/74.png";
import img105 from "../../assets/ShowCase/105.png";
import img120 from "../../assets/ShowCase/120.png";
import img168 from "../../assets/ShowCase/168.png";
import img202 from "../../assets/ShowCase/202.png";

import img207 from "../../assets/ShowCase/207.png";
import img208 from "../../assets/ShowCase/208.png";
import img221 from "../../assets/ShowCase/221.png";
import img226 from "../../assets/ShowCase/226.png";
import img234 from "../../assets/ShowCase/234.png";
import img290 from "../../assets/ShowCase/290.png";
import img301 from "../../assets/ShowCase/301.png";
import img316 from "../../assets/ShowCase/316.png";
import img325 from "../../assets/ShowCase/325.png";
import img333 from "../../assets/ShowCase/333.png";

import ETH from "../../assets/deso.svg";

const Section = styled.section`
  min-height: 100vh;
  width: 100vw;
  background-color: ${(props) => props.theme.text};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;

  & > *:first-child {
    animation-duration: 25s;

    @media (max-width: 30em) {
      animation-duration: 20s;
    }
  }
  & > *:last-child {
    animation-duration: 20s;

    @media (max-width: 30em) {
      animation-duration: 15s;
    }
  }
`;

const move = keyframes`
  0%{ transform: translateX(100%)};
  100%{ transform: translateX(-100%)};
`;

const Row = styled.div`
  white-space: nowrap;
  box-sizing: content-box;
  margin: 2rem 0;
  display: flex;

  animation: ${move} linear infinite ${(props) => props.direction};
`;

const ImgContainer = styled.div`
  width: 15rem;
  margin: 0 1rem;
  cursor: pointer;
  border-radius: 1rem !important;
  @media (max-width: 48em) {
    width: 12rem;
  }

  @media (max-width: 30em) {
    width: 10.2rem;
  }

  img {
    width: 100%;
    height: auto;
  }
`;

const Details = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0.8rem 1rem;
  background-color: ${(props) => props.theme.text};
  border: 2px solid ${(props) => `rgba(${props.theme.bodyRgba}, 0.5)`};
  gap: 4px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;

  span {
    font-size: ${(props) => props.theme.fontsm};
    color: ${(props) => `rgba(${props.theme.bodyRgba}, 0.5)`};
    font-weight: 600;
    line-height: 1.5rem;
  }

  h1 {
    font-size: ${(props) => props.theme.fontmd};
    color: ${(props) => props.theme.body};
    font-weight: 600;

    @media (max-width: 30em) {
      font-size: ${(props) => props.theme.fontsm};
    }
  }
`;

const Price = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  img {
    width: 1rem;
    height: auto;
  }
`;

const NftItem = ({ img, number = 0, price = 0, passRef }) => {
  let play = (e) => {
    passRef.current.style.animationPlayState = "running";
  };
  let pause = (e) => {
    passRef.current.style.animationPlayState = "paused";
  };

  return (
    <ImgContainer onMouseOver={(e) => pause(e)} onMouseOut={(e) => play(e)}>
      <img
        src={img}
        alt="Deso Doodles"
        style={{
          borderTopLeftRadius: "20px",
          borderTopRightRadius: "20px",
        }}
      />
      <Details>
        <div>
          <span>Deso Doodles</span> <br />
          <h1>#{number}</h1>
        </div>

        <div>
          <span>Price</span>
          <Price>
            <h1 style={{ marginRight: "2.5px" }}>{Number(price).toFixed(1)}</h1>
            <img src={ETH} alt="ETH" style={{width:"10px"}}/>
          </Price>
        </div>
      </Details>
    </ImgContainer>
  );
};

const Showcase = () => {
  const Row1Ref = useRef(null);
  const Row2Ref = useRef(null);

  return (
    <Section id="showcase">
      <Row direction="none" ref={Row1Ref}>
        <NftItem img={img27} number={27} price={0.5} passRef={Row1Ref} />
        <NftItem img={img38} number={38} price={0.5} passRef={Row1Ref} />
        <NftItem img={img44} number={44} price={0.5} passRef={Row1Ref} />
        <NftItem img={img46} number={46} price={0.5} passRef={Row1Ref} />
        <NftItem img={img73} number={73} price={0.5} passRef={Row1Ref} />
        <NftItem img={img74} number={74} price={0.5} passRef={Row1Ref} />
        <NftItem img={img105} number={105} price={0.5} passRef={Row1Ref} />
        <NftItem img={img120} number={120} price={0.5} passRef={Row1Ref} />
        <NftItem img={img168} number={168} price={0.5} passRef={Row1Ref} />
        <NftItem img={img202} number={202} price={0.5} passRef={Row2Ref} />
      </Row>
      <Row direction="reverse" ref={Row2Ref}>
        <NftItem img={img207} number={207} price={0.5} passRef={Row2Ref} />
        <NftItem img={img208} number={208} price={0.5} passRef={Row2Ref} />
        <NftItem img={img221} number={221} price={0.5} passRef={Row2Ref} />
        <NftItem img={img226} number={226} price={0.5} passRef={Row2Ref} />
        <NftItem img={img234} number={234} price={0.5} passRef={Row2Ref} />
        <NftItem img={img290} number={290} price={0.5} passRef={Row2Ref} />
        <NftItem img={img301} number={301} price={0.5} passRef={Row2Ref} />
        <NftItem img={img316} number={316} price={0.5} passRef={Row2Ref} />
        <NftItem img={img325} number={325} price={0.5} passRef={Row2Ref} />
        <NftItem img={img333} number={333} price={0.5} passRef={Row2Ref} />
      </Row>
    </Section>
  );
};

export default Showcase;
