export const light = {
  body: "#fff",
  text: "#202020", // black shade
  bodyRgba: "255, 255, 255",
  textRgba: "32, 32, 32",

  carouselColor: "#EEEDDE",

  fontxs: "0.75em",
  fontsm: "0.750rem",
  fontmd: "1em", // 1em = 16px
  fontlg: "1.25em",
  fontxl: "2em",
  fontxxl: "3em",
  fontxxxl: "4em",

  fontButton: "0.750rem",

  navHeight: "5rem",
};

export const dark = {
  body: "#202020 !important",
  text: "#fff !important", // black shade
  bodyRgba: "32, 32, 32",
  textRgba: "255, 255, 255",

  carouselColor: "#EEEDDE",

  fontxs: "0.75em",
  fontsm: "0.750rem",
  fontmd: "1em", // 1em = 16px
  fontlg: "1.25em",
  fontxl: "2em",
  fontxxl: "3em",
  fontxxxl: "4em",

  fontButton: "0.750rem",

  navHeight: "5rem",
};
