import React from "react";
import styled from "styled-components";

const Btn = styled.button`
  display: inline-block;
  background-color: ${(props) => props.theme.text};
  color: ${(props) => props.theme.body};
  outline: none;
  border: none;

  font-size: ${(props) => props.theme.fontsm};
  padding: 0.9rem 2.3rem;
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.2s ease;
  position: relative;

  &:hover {
    transform: scale(0.9);
  }

  &::after {
    content: " ";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0);
    border: 2px solid ${(props) => props.theme.text};
    width: 100%;
    height: 100%;
    border-radius: 50px;
    transition: all 0.2s ease;
  }

  &:hover::after {
    transform: translate(-50%, -50%) scale(1);
    padding: 0.3rem;
  }

  &:disabled {
    background-color: #cccccc; // Example: gray background
    color: #666666; // Example: darker text color
    cursor: not-allowed; // Change cursor to indicate the button is disabled

    // Any other styles you want to apply to the disabled button
  }
`;

const Spinner = styled.div`
  border: 2px solid rgba(255, 255, 255, 0.2);
  border-top: 2px solid ${(props) => props.theme.body};
  border-radius: 50%;
  width: 5px;
  height: 5px;
  animation: spin 1s linear infinite;
  margin-left : 5px;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const Button = ({ text, onClick, disabled }) => {
  return (
    <Btn onClick={onClick} disabled={disabled}>
      <div style={{ display: "flex" }}>
        <span> {text}</span>
        <span>{disabled && <Spinner />}</span>
      </div>
    </Btn>
  );
};

export default Button;
