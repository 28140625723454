import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import React, { useLayoutEffect, useRef } from "react";
import styled from "styled-components";
import Accordion from "../Accordion";

const Section = styled.section`
  min-height: 100vh;
  height: auto;
  width: 100vw;
  background-color: ${(props) => props.theme.text};
  position: relative;
  color: ${(props) => props.theme.body};

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const Title = styled.h1`
  font-size: ${(props) => props.theme.fontxxl};
  text-transformation: uppercase;
  color: ${(props) => props.theme.body};

  margin: 1rem auto;
  border-bottom: 2px solid ${(props) => props.theme.cauroselColor};
  width: fit-content;

  @media (max-width: 48em) {
    font-size: ${(props) => props.theme.fontxl};
  }
`;

const Container = styled.div`
  width: 75%;
  margin: 2rem auto;

  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 64em) {
    width: 80%;
  }

  @media (max-width: 48em) {
    width: 90%;
    flex-direction: column;
  }

  & > *::last-child {
    & > *::first-child {
      margin-top: 0;
    }
  }
`;

const Box = styled.div`
  width: 45%;

  @media (max-width: 64em) {
    width: 90%;
    align-self: center;
  }
`;

const Faq = () => {
  const ref = useRef(null);
  gsap.registerPlugin(ScrollTrigger);
  useLayoutEffect(() => {
    let element = ref.current;

    ScrollTrigger.create({
      trigger: element,
      start: "top top",
      end: "bottom top",
      pin: true,
      pinSpacing: false,
      scrub: true,
      // markers: true,
    });

    return () => {
      ScrollTrigger.kill();
    };
  }, []);

  return (
    <Section ref={ref} id="faq">
      <Title>FAQ</Title>
      <Container>
        <Box>
          <Accordion title=" What is Deso Doodles??">
            Deso Doodles is a unique collection of 333 generative NFTs available
            on the Deso blockchain. Each NFT is a one-of-a-kind digital
            collectible created through algorithmic artistry.
          </Accordion>
          <Accordion title="How can I mint a Deso Doodle?">
            You can mint your own Deso Doodle on our website, desodoodles.com,
            by connecting your Deso blockchain wallet and paying a minting fee
            of 0.5 Deso.
          </Accordion>
          <Accordion title="Are the NFTs randomly assigned?">
            Yes, each Deso Doodle is randomly assigned during the minting
            process, giving everyone an equal chance to receive the rarest NFTs
            in the collection.
          </Accordion>
        </Box>
        <Box>
          <Accordion title="What is the price of each Deso Doodle?">
            Each Deso Doodle is minted at a flat rate of 0.5 Deso, regardless of
            its rarity.
          </Accordion>
          <Accordion title="How often are revenue shares distributed to NFT holders?">
            Revenue shares are distributed to NFT holders every three months,
            amounting to 5% of the project's total revenue.
          </Accordion>
          <Accordion title="How can I stay updated on Deso Doodles' developments?">
            For the latest updates, join our community channels on Deso, and
            keep an eye on our official website and whitepaper. We regularly
            share news, updates, and exclusive holder information.
          </Accordion>
        </Box>
      </Container>
    </Section>
  );
};

export default Faq;
